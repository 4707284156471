import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetLatest } from '@tw/tw-runtime-react';

/**
 * 倒计时
 */
export function useCountdown(config?: UseCountdownConfig) {
  const [remained, setRemained] = useState<number>();
  const [isCounting, setCounting] = useState(false);
  const timerHandle = useRef(0);
  const updatePeriod = config?.period ?? DEF_UPDATE_PERIOD_MS;

  const getRemind = useGetLatest(remained);

  const startTimer = useCallback(() => {
    timerHandle.current = setInterval(() => {
      const remained = getRemind()!;
      const target = remained - updatePeriod;
      if (target < 0) {
        setCounting(false);
        clearInterval(timerHandle.current);
        setRemained(0);
        config?.onFinish();
        return;
      } else {
        //
        setRemained(target);
      }
    }, updatePeriod) as unknown as number;
  }, [config, getRemind, updatePeriod]);

  // 启动定时
  const start = useCallback(
    (time: number) => {
      if (time < 0) {
        return;
      }
      setCounting(true);
      setRemained(time);
      startTimer();
    },
    [startTimer]
  );
  const stop = useCallback(() => {
    setCounting(false);
    clearInterval(timerHandle.current);
  }, []);

  useEffect(() => {
    return () => {
      setCounting(false);
      clearInterval(timerHandle.current);
    };
  }, []);
  return {
    isCounting,
    remained,
    start,
    stop
  };
}

const DEF_UPDATE_PERIOD_MS = 1000;
export interface UseCountdownConfig {
  /**
   * 更新周期（毫秒）
   */
  period?: number;
  onFinish?: () => void;
}
