import { useEffect, useLayoutEffect } from 'react';
import { BfAppContext } from '@tw/tw-runtime';
import { Text } from 'react-native';

export const Splash = ({ route, navigation }) => {
  console.log('加载参数： ', route.params);
  const loadParams = route.params as LoadParams;
  const routeService = BfAppContext.getInstance().getRouteService();
  const authStorageService = BfAppContext.getInstance().getAuthStorageService();
  const messageService = BfAppContext.getInstance().getMessageService();

  useEffect(() => {
    if (!loadParams) {
      messageService.showError('缺少加载参数');
      return;
    }
    const { token, page, ...restQuery } = loadParams;
    authStorageService.setToken(token);
    // messageService.showInfo('跳转到：' + page + ' ' + JSON.stringify(restQuery));
    // setTimeout(()=>{
    routeService.replace({
      path: page,
      query: restQuery
    });
    // },300)
  }, []);

  // useEffect(() => {
  //   const startPage = async () => {
  //     const phone = await CallModule.getPhoneNumber();
  //     const token = authStorageService.getToken();
  //     // 没有登录
  //     if (!token) {
  //       routeService.replace({
  //         path: ROUTE_NAMES.Login
  //       });
  //       return;
  //     }
  //     routeService.replace({
  //       path: ROUTE_NAMES.Home
  //     });
  //   };
  //   startPage();
  // }, [authStorageService, routeService]);
  return <Text>加载参数:{JSON.stringify(route.params)}</Text>;
};

export interface LoadParams {
  page: string;
  token: string;
  [key: string]: any;
}
