import { BfAppContext, CoordinatesValue, IMessageService, IStructureService, StructureInfo } from '@tw/tw-runtime';
import { ResPartnerDTO, ResPartnerService } from '@tw/lock-api-gen';
import { PhoneCallUtils } from '@/app/helpers/phone-call-utils';

export class CommonActionProxy {
  private static instance = new CommonActionProxy();
  private partnerService: ResPartnerService | undefined;
  private messageService: IMessageService | undefined;
  public static getInstance() {
    return this.instance;
  }

  private onLocationSelect: OnLocationOpenInMapListener | undefined;

  public setOnLocationSelect(listener: OnLocationOpenInMapListener) {
    this.onLocationSelect = listener;
  }

  /**
   * 打开 location
   */
  openLocationInMap(location: CoordinatesValue | undefined) {
    if (!this.onLocationSelect) {
      console.error('onLocationSelect listener is null');
      return;
    }
    if (!location) {
      this.getMessageService().showError('未获取到地址信息');
      return;
    }
    this.onLocationSelect(location);
  }

  /**
   * 拨打电话
   */
  async callPhone(phone: string, partnerId: ResPartnerDTO) {
    const res = await this.getPartnerService().RelMobile({
      partnerId: partnerId.id,
      mobile: phone
    });
    if (!res.mobile) {
      this.messageService.showError('没有查询到手机号');
      return;
    }
    PhoneCallUtils.callPhone(res.mobile);
  }

  /**
   * 启用
   */
  async enable(dto: any, dataService: IStructureService, structInfo: StructureInfo) {
    //
    await dataService.update(
      {
        id: dto.id,
        active: true
      },
      structInfo
    );
    this.getMessageService().showSuccess('启用成功');
  }

  /**
   * 禁用
   * @param dto
   * @param dataService
   * @param structInfo
   */
  async disable(dto: any, dataService: IStructureService, structInfo: StructureInfo) {
    //
    await dataService.update(
      {
        id: dto.id,
        active: false
      },
      structInfo
    );
    this.getMessageService().showSuccess('禁用成功');
  }

  getPartnerService() {
    if (this.partnerService) {
      return this.partnerService;
    }
    const apiService = BfAppContext.getInstance().getApiService();
    this.partnerService = new ResPartnerService(apiService);
    return this.partnerService;
  }

  getMessageService() {
    if (this.messageService) {
      return this.messageService;
    }
    this.messageService = BfAppContext.getInstance().getMessageService();
    return this.messageService;
  }

  callPhoneDirectly(departPhone: string | undefined) {
    if (!departPhone) {
      this.getMessageService().showError('没有手机号');
      return;
    }
    PhoneCallUtils.callPhone(departPhone);
  }
}

export type OnLocationOpenInMapListener = (location: CoordinatesValue) => void;
