import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white'
  },
  header: {
    paddingHorizontal: 28
  },

  //
  loginFrom: {
    // paddingHorizontal: 28,
    // marginBottom: 100
  },
  other: {},
  loginOther: {
    justifyContent: 'space-between',
    marginTop: TwTheme.getSpace(6),
    paddingHorizontal: 24
  },
  loginChoose: {
    ...TwTheme.values.textSizes.sm, //16
    color: TwTheme.values.textColors.third
  },
  forgetPwd: {
    ...TwTheme.values.textSizes.sm, //16
    color: TwTheme.values.primaryColors.base
  }
});
