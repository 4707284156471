import { TwButton, TwListItem, TwTypography } from '@tw/tw-components-rn';
import React, { FC, useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { Text, TouchableOpacity, View } from 'react-native';

import { CurrentAccountProps } from './type';
import { Octicons } from '@expo/vector-icons';
import { styles } from './styles';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { BfAppContext } from '@tw/tw-runtime';

export const CurrentAccount: FC<CurrentAccountProps> = (props) => {
  const { accountNumber, allowChangeAccount, wxOpenid } = props;

  return (
    <TwListItem
      title={'当前登录: ' + accountNumber}
      pressable={false}
      style={styles.listItem}
      suffix={
        <TwButton
          variant={'unstyled'}
          style={styles.button}
          isDisabled={!allowChangeAccount}
          size={'sm'}
          onPress={() =>
            BfAppContext.getInstance()
              .getRouteService()
              .push({
                path: ROUTE_NAMES.AuthSwitchAccount,
                query: {
                  wxOpenid: wxOpenid
                }
              })
          }
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Octicons name="arrow-switch" size={16} color="#2A63B2" />
            <TwTypography.Text style={styles.switchText} size={'sm'} color={'#2A63B2'}>
              切换账号
            </TwTypography.Text>
          </View>
        </TwButton>
      }
    />
  );
};
