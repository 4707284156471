import { MaterialIcons } from '@expo/vector-icons';
import { styles } from './styles';
import React from 'react';

export const orderInterviewRateItems = [
  {
    icon: (color, size) => (
      <MaterialIcons style={styles.icon} name="sentiment-very-dissatisfied" size={size} color={color} />
    ),
    label: '非常不满意'
  },
  {
    icon: (color, size) => (
      <MaterialIcons style={styles.icon} name="sentiment-dissatisfied" size={size} color={color} />
    ),
    label: '不满意'
  },

  {
    icon: (color, size) => <MaterialIcons style={styles.icon} name="sentiment-neutral" size={size} color={color} />,
    label: '一般'
  },
  {
    icon: (color, size) => (
      <MaterialIcons style={styles.icon} name="sentiment-satisfied-alt" size={size} color={color} />
    ),
    label: '满意'
  },
  {
    icon: (color, size) => <MaterialIcons style={styles.icon} name="sentiment-satisfied" size={size} color={color} />,
    label: '非常满意'
  }
];
