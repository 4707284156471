import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: TwTheme.values.backgroundColors.page
  },
  content: {
    flex: 1
  },
  footer: {}
});
