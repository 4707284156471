import { FC } from 'react';
import { OrderProductItemProps } from '@/sale/components/order-product-item/type';
import { ProductOrderLayout } from '@/sale/components/product-order-layout/ProductOrderLayout';
import { ProductProduct } from '@/sale/components/product-product/ProductProduct';
import { Text } from 'react-native';
import { styles } from '@/sale/components/order-product-item/styles';
export const OrderProductItem: FC<OrderProductItemProps> = (props) => {
  const { value } = props;
  return (
    <ProductOrderLayout
      product={<ProductProduct value={value.productId} />}
      qtyOrdered={<Text style={styles.label}>{value.qtyOrdered ?? '--'}</Text>}
      unitPrice={<Text style={styles.label}>{value.unitPrice ? `${value.unitPrice}元` : '--'}</Text>}
    />
  );
};
