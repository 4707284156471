import { Text, View, ViewProps } from 'react-native';
import { styles } from './styles';
import { TwButton, TwCheckbox, TwInput } from '@tw/tw-components-rn';
import { FC } from 'react';
import { useAppStore, useBfPageStore, useStoreState } from '@tw/tw-runtime-react';
import { LoginStore } from '@/auth/stores/login/login-store';

export const LoginForm: FC<ViewProps> = (props) => {
  const store = useBfPageStore<LoginStore>();
  const appStore = useAppStore();
  const phone = useStoreState(store, (state) => state.phone);
  const protocolAgree = useStoreState(store, (state) => state.protocolAgree);
  const userLoginLoading = useStoreState(appStore, (state) => state.isDoingLogin);

  const onLoginClick = () => {
    store.onLoginClick();
  };
  const onPhoneChange = (text: string) => {
    store.onPhoneChange(text);
  };
  const onAgreeCheck = (checked: boolean) => {
    store.onAgreeCheck(checked);
  };
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.phoneContainer}>
        <Text style={styles.label}>手机号码</Text>
        <TwInput
          placeholder={'请输入手机号码'}
          value={phone}
          onChangeText={onPhoneChange}
          style={styles.input}
          variant={'unstyled'}
        ></TwInput>
      </View>
      <TwButton style={styles.loginButton} testID={'LoginBtn'} onPress={onLoginClick} isLoading={userLoginLoading}>
        登录
      </TwButton>

      <View style={styles.confirmContainer}>
        <TwCheckbox checked={protocolAgree} onChange={onAgreeCheck} />
        <Text style={styles.confirmText}>我已阅读同意</Text>
        <Text style={styles.confirmLink}>XXX协议</Text>
      </View>
    </View>
  );
};

interface FormData {
  phone: string;
}
