import { BfPageStore } from '@tw/tw-runtime';
import { LoginState } from '@/auth/stores/login/types';
import { LoginHelper } from '@/auth/stores/login/helper';

export class LoginStore extends BfPageStore<LoginState> {
  constructor() {
    super({});
  }

  protected getInitialState(): LoginState {
    return { ...super.getInitialState(), phone: '17122222222' };
  }

  onPhoneChange(phone: string) {
    this.setState({
      phone
    });
  }

  /**
   * 登录点击
   */
  onLoginClick() {
    //
    if (!this.checkProtocolAgree()) {
      return;
    }
    if (!this.checkPhoneValidate()) {
      return;
    }
    this.routeService.push({
      path: 'Verify',
      query: {
        phone: this.getState().phone
      }
    });
  }

  private checkProtocolAgree(): boolean {
    if (this.getState().protocolAgree) {
      return true;
    }
    this.messageService.showError('需要同意协议');
    return false;
  }

  private checkPhoneValidate(): boolean {
    const { phone } = this.getState();
    if (!phone) {
      this.messageService.showError('请输入手机号');
      return false;
    }
    if (!LoginHelper.validatePhone(phone)) {
      this.messageService.showError('请输入有效的手机号');
      return false;
    }
    return true;
  }

  onAgreeCheck(checked: boolean) {
    this.setState({
      protocolAgree: checked
    });
  }
}
