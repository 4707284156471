import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    paddingHorizontal: TwTheme.values.spaces[4]
  },
  logo: {
    width: '100%',
    paddingHorizontal: TwTheme.values.spaces[4],
    marginTop: TwTheme.values.spaces[5]
  },
  logoText: {
    marginTop: TwTheme.values.spaces[2],
    marginBottom: TwTheme.values.spaces[5],

    // fontFamily: OPPOSans;
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 30
  },
  list: {},
  switchText: {
    marginLeft: TwTheme.values.spaces[1]
  }
});
