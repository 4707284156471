import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  listItem: {
    // paddingHorizontal: TwTheme.values.spaces[1],
    paddingVertical: TwTheme.values.spaces[1]
  },
  button: {
    paddingVertical: TwTheme.values.spaces[1]
  },
  switchText: {
    marginLeft: TwTheme.values.spaces[1]
  }
});
