export const ROUTE_NAMES = {
  // 闪屏
  Splash: 'Splash',
  // 登录
  Login: 'Login',
  // 主页
  Home: 'Home',
  // 认证
  Verify: 'Verify',
  SaleOrderList: 'SaleOrderList',
  SaleOrderInfo: 'SaleOrderInfo',
  // 登录页面
  AuthLoginNew: 'AuthLoginNew',
  // 切换账号
  AuthSwitchAccount: 'AuthSwitchAccount'
};
