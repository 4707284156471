import React, { useState, useEffect } from 'react';
import { SaleOrderListPage } from '../../stores/sale-order-list/sale-order-list-page';

import { TwBfListView } from '@tw/tw-platform-rn';
import { View } from 'react-native';
import { CurrentAccount } from '@/sale/components/current-account/CurrentAccount';
import { usePageStoreLifecycle, useStoreState } from '@tw/tw-runtime-react';
import { TwLoadingBlock } from '@tw/tw-components-rn';
import { AccountSwitchPage } from '@/sale/stores/account-switch/account-switch-page';
export const SaleOrderList = ({ route }) => {
  const [page] = useState(() => new SaleOrderListPage());
  usePageStoreLifecycle(page, route.params);

  const accountPage = AccountSwitchPage.getInstance();
  const [store] = useState(accountPage);
  useEffect(() => {
    store.onLoad(route.params);
  }, [route.params, store]);

  const authList = useStoreState(store, (state) => state.authList);

  console.log('===SaleOrderList', authList);

  const currentNumber = authList?.find((item) => item.isCurrentUsing)?.mobile;
  const allowChangeAccount = authList?.length > 1;

  if (!authList) {
    return <TwLoadingBlock tip={'加载中'} />;
  }

  return (
    <>
      <CurrentAccount accountNumber={currentNumber} allowChangeAccount={true} wxOpenid={route.params?.wxOpenid} />
      <TwBfListView page={page} showBack={false} showPageHeader={false} />
    </>
  );
};
