import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  phoneContainer: {
    // flexDirection: 'row',
    // paddingHorizontal: TwTheme.values.spaces['3'],
    // borderColor: TwTheme.values.borderColors.base,
    // borderBottomWidth: TwTheme.values.borderWidths.base,
    // alignItems: 'center',
    width: '100%',
    marginBottom: TwTheme.getSpace(6)
  },
  label: {
    ...TwTheme.values.textSizes.base,
    color: TwTheme.values.textColors.base,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  input: {
    // overflow:'hidden',

    // flex:1,
    flexGrow: 1,
    flexShrink: 1,
    ...TwTheme.values.textSizes.base
  },
  container: {
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal
  },
  loginButtonContainer: {
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    marginTop: TwTheme.getSpace(6)
  },
  loginButton: {},
  confirmContainer: {
    flexDirection: 'row',
    // marginTop: TwTheme.getSpace(6),
    alignItems: 'center',
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal
  },
  confirmCheck: {},
  confirmText: {
    color: TwTheme.values.textColors.third,
    marginLeft: TwTheme.values.spaces['2']
  },
  confirmTextWithNoMargin: {
    color: TwTheme.values.textColors.third
  },
  confirmLink: {
    marginLeft: 1,
    color: TwTheme.values.textColors.third,
    textDecorationLine: 'underline'
  },
  loginOtherContainer: {
    paddingHorizontal: TwTheme.values.spaces.pageHorizontal,
    marginTop: TwTheme.getSpace(6)
  }
});
