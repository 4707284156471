import { FC } from 'react';
import { Text } from 'react-native';
import { orderInterviewRateItems } from './items';

export const OrderInterviewRateIcon: FC<OrderInterviewRateIconProps> = (props) => {
  const { value } = props;
  if (!value) {
    return <Text>--</Text>;
  }
  const item = orderInterviewRateItems[value];
  return <>{item.label}</>;
};

export interface OrderInterviewRateIconProps {
  /**
   * 评分
   */
  value?: number;
}
