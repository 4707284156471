import { BfUserInfo, BfUserService } from '@tw/tw-runtime';
import { ResAuthorizationService, ResLoginRequestModel } from '@tw/lock-api-gen';

export class UserService extends BfUserService {
  private authService: ResAuthorizationService;
  constructor(api) {
    super(api);
    this.authService = new ResAuthorizationService(api);
  }

  async requestLogin(params: ResLoginRequestModel) {
    const res = await this.authService.login(params);
    return res.token!.token!;
    // console.warn('使用mockToken');
    // return '48c84b6f-bd99-4c84-aff0-116b799d40d6';
  }

  async requestLogout(): Promise<void> {
    return await this.authService.logout();
  }

  async fetchUserInfo(): Promise<BfUserInfo> {
    return await this.authService.userInfoRead();
  }
}
