import { AbstractDTO, BfAbstractIntentNavigator, BfIntent, BfIntentResult } from '@tw/tw-runtime';

import { ROUTE_NAMES } from '@/app/navigation/route-names';

export interface BfAccountSelectIntent extends BfIntent {
  selected?: AbstractDTO | undefined;

  structure: {
    /**
     * bo Id
     */
    id: string;
    /**
     * boDomain
     */
    domain: string;
  };

  query?: {
    name: string;
  };
}

export class AccountSelectNavigator extends BfAbstractIntentNavigator<AbstractDTO, BfAccountSelectIntent> {
  get ROUTE_NAME(): string {
    return ROUTE_NAMES._AssignUserSelect;
  }
  start(intent?: BfAccountSelectIntent): Promise<BfIntentResult<AbstractDTO>> {
    console.log('intent:', intent);
    return super.start(intent);
  }
}
export const BfAccountSelectNavigator = new AccountSelectNavigator();
