import { BfAppContext, BfUserLoginParams, IPushMessageEvent, RouteParamsObject } from '@tw/tw-runtime';
import { navigationRef, RnAppStore, RnRouterService } from '@tw/tw-runtime-rn';
import { CommonActions } from '@react-navigation/native';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export class AppStore extends RnAppStore {
  protected isPublicPath(route: RouteParamsObject): boolean {
    return true;
  }
  /**
   * 全局推送消息
   * @param message
   * @protected
   */
  protected onGlobalPushMessage(message: IPushMessageEvent) {}

  /**
   * 解绑推送
   * @param silent
   * @protected
   */
  protected async unBindPushNotice(silent: boolean) {
    super.unBindPushNotice(silent);
  }

  protected showPwdSettingPage(): void {
    this.getMessageService().showError('没有实现：设置密码');
  }

  protected async showSettingPwdSkipConfirm(params: BfUserLoginParams): Promise<boolean> {
    return true;
  }

  protected validateWeekPwd(params: BfUserLoginParams): boolean {
    return false;
  }
  async logout(silent?: boolean): Promise<void> {
    await super.logout(silent);
    // await this.goLoginPage();
  }

  protected async afterLogoutSuccess(): Promise<void> {
    this.logger.debug('退出登录成功');
    this.menuStore.onLogout();
    const routeService = BfAppContext.getInstance().getRouteService() as RnRouterService;
    await routeService.replace({
      path: ROUTE_NAMES.Login
    });
  }

  protected async initializeServices(): Promise<void> {
    await super.initializeServices();
  }

  async goLoginPage() {
    navigationRef.current?.dispatch((state) => {
      return CommonActions.reset({
        ...state,
        routes: [
          {
            name: ROUTE_NAMES.Login
          }
        ],
        index: 0
      });
    });
  }

  async goEntryPage() {
    navigationRef.current?.dispatch((state) => {
      return CommonActions.reset({
        ...state,
        routes: [
          {
            name: ROUTE_NAMES.SaleOrderList
          }
        ],
        index: 0
      });
    });
    // this.getRouteService().replace({
    //   path: 'Home'
    // });
  }
}
