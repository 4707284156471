import { BfErrorJsHandler, BfErrorNativeHandler, IBfErrorHandler } from '@/app/error-handler/error-handler-defs';

export const BfErrorHandler: IBfErrorHandler = {
  setJsErrorHandler(handler: BfErrorJsHandler): void {
    //
    window.onerror = (event, source, lineno, colno, error) => {
      handler(error, true);
    };
  },
  setNativeErrorHandler(handler: BfErrorNativeHandler): void {
    //
  }
};
