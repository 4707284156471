import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  pinTextStyle: {
    fontWeight: 'bold',
    ...TwTheme.values.textSizes.lg
  },
  cellUnderlineStyle: {
    borderBottomWidth: 2,
    borderColor: TwTheme.values.textColors.secondary
  },
  cellUnderlineStyle__focused: {
    borderColor: TwTheme.values.primaryColors.base
  }
});
