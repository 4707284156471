import {
  BfAppContext,
  BfIntentResultType,
  BoCommandItem,
  BoStructure,
  CoordinatesValue,
  PageDeviceType,
  PageSchemaRequestType,
  newEnumValue
} from '@tw/tw-runtime';
import { BfClListViewPage, BfListViewPageSchema } from '@tw/tw-platform-react';
import {
  HrEmployeeDTO,
  HrEmployeeRestApiService,
  OrderExecutorTypeEnumValue,
  ResPartnerService,
  ResWxAuthService,
  SaleOrderDTO,
  SaleOrderService
} from '@tw/lock-api-gen';
import { SaleOrderListLoadParam, SaleOrderListState } from './types';

import { Alert } from 'react-native';
import { BfAccountSelectNavigator } from '@/app/components/tw-account-select/bf-account-select-navigator';
import { BfViewPageNavigationProxy } from '@tw/tw-platform-react';
import { CommonActionProxy } from '@/app/components/common-action-proxy/common-action-proxy';
import { PhoneCallUtils } from '@/app/helpers/phone-call-utils';
import { ROUTE_NAMES } from '@/app/navigation/route-names';

export class SaleOrderListPage extends BfClListViewPage<BoStructure, BfListViewPageSchema, SaleOrderListState> {
  private partnerService: ResPartnerService;
  private saleOrderService: SaleOrderService;
  private employeeService: HrEmployeeRestApiService;
  private wxAuthService: ResWxAuthService;

  constructor() {
    super({
      type: PageSchemaRequestType.listView,
      deviceType: PageDeviceType.mobile,
      uiviewQname: 'uiview://partnerOrderList',
      qname: 'doc://sale/docPartnerOrder'
    });
    const apiService = BfAppContext.getInstance().getApiService();
    this.partnerService = new ResPartnerService(apiService);
    this.saleOrderService = new SaleOrderService(apiService);
    this.employeeService = new HrEmployeeRestApiService(apiService);
    this.wxAuthService = new ResWxAuthService(apiService);
  }

  protected fetchPageSchema(): Promise<BfListViewPageSchema | undefined> {
    console.log('加载Schema');
    this.logger.debug('加载Schema');
    return super.fetchPageSchema();
  }

  async onLoad(params?: SaleOrderListLoadParam) {
    console.log('=====加载列表', params);
    // await this.getAuthList(params?.wxOpenid);

    return await super.onLoad(params);
  }

  protected getCreatePagePath(): string {
    return 'SaleOrderInfo';
  }

  protected getEditPagePath(): string {
    return 'SaleOrderInfo';
  }

  protected getPreviewPagePath(): string {
    return 'SaleOrderInfo';
  }

  onContextCommand(command: BoCommandItem, record: SaleOrderDTO) {
    switch (command.id) {
      case 'navigation':
        this.onNavigationClick(record.address);
        return;
      case 'call':
        this.onCall(record);
        return;
      case 'urgent':
        this.onUrgen(record);
        return;
      case 'distribute':
        this.onDistribute(record);
        return;
      case 'assign':
        this.setShowAssignSelectPopup(true);
        return;
      case 'discard':
        this.onDiscard(record);
        return;
      case 'acceptOrder':
        this.onAcceptOrder(record);
        return;
      case 'transfer':
        this.onTransfer(record);
        return;
    }
    super.onContextCommand(command, record);
  }

  protected onNavigationClick(location: CoordinatesValue) {
    CommonActionProxy.getInstance().openLocationInMap(location);
  }

  onMapMenuClose() {
    this.setState({
      showMapMenu: false,
      selectedLocation: undefined
    });
  }

  private async onCall(record: SaleOrderDTO) {
    const res = await this.partnerService.RelMobile({
      mobile: record.linkmanPhone,
      partnerId: record.partnerId!.id
    });
    if (!res.mobile) {
      this.messageService.showError('没有查询到手机号');
      return;
    }
    PhoneCallUtils.callPhone(res.mobile);
    this.logger.debug('获取到来单');
    this.logger.debug(res);
  }

  /**
   * 加急
   * @param record
   * @private
   */
  private async onUrgen(record: SaleOrderDTO) {
    await this.saleOrderService.urgent(record);
    // await this.reloadListData();
    this.messageService.showSuccess('加急成功');
  }

  /**
   * 分发
   * @param record
   * @private
   */
  private async onDistribute(record: SaleOrderDTO) {}

  /**
   * 指派按钮点击
   * @param record
   * @private
   */
  async onAssign(record: SaleOrderDTO) {
    try {
      const res = await BfAccountSelectNavigator.start({
        selected: record,
        structure: {
          id: this.employeeService.apiStructureId,
          domain: this.employeeService.apiDomainId
        },
        query: {
          name: HrEmployeeRestApiService.NAME
        }
      });
      if (res.type === BfIntentResultType.OK) {
        this.saleOrderService.assign({
          orderId: record,
          executor: res.data,
          executorType: newEnumValue(OrderExecutorTypeEnumValue.internal)
        });
        await this.reloadListData();
        this.messageService.showSuccess('指派成功');
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 工单指派
   */
  async assign(executor: HrEmployeeDTO) {}

  /**
   * 工单废弃
   */
  async onDiscard(record: SaleOrderDTO) {
    this.setCurrentOrder(record);
    this.setShowAbandonPopup(true);
    // this.saleOrderService.discard({});
    // // this.messageService.showWarn('Todo: 工单废弃, 没找到接口');
    // await this.reloadListData();
  }

  /**
   * 工单接单
   */
  async onAcceptOrder(record: SaleOrderDTO) {
    await this.saleOrderService.claim(record);
    this.messageService.showSuccess('接单成功');
    await this.reloadListData();
  }

  // 指派popup
  setShowAssignSelectPopup(visible: boolean) {
    this.setState({
      showAssignSelectPopup: visible
    });
  }

  setShowAbandonPopup(visible: boolean) {
    this.setState({
      showAbandonPopup: visible
    });
  }

  private setCurrentOrder(order: SaleOrderDTO | undefined) {
    this.setState({
      currentOrder: order
    });
  }

  doAbandonOrder(reason: string) {
    const { currentOrder } = this.getState();
    this.saleOrderService.discard({
      orderId: currentOrder,
      remark: reason
    });
    this.setShowAbandonPopup(false);
    this.messageService.showSuccess('废弃成功');
    this.reloadListData();
  }

  private onTransfer(record: SaleOrderDTO) {
    //
    BfViewPageNavigationProxy.getInstance().edit(ROUTE_NAMES.SaleOrderTransferInfo, record.id);
  }
}
