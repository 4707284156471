import { StyleSheet } from 'react-native';
import { TwTheme } from '@tw/tw-runtime-rn';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: 200,
    justifyContent: 'center'
  },
  logo: {
    width: 56,
    height: 56,
    marginBottom: TwTheme.values.spaces['2']
  },
  slogan: {
    ...TwTheme.values.textSizes['2xl'],
    color: TwTheme.values.primaryColors.base
  }
});
