import { RnAuthStorageService } from '@tw/tw-runtime-rn';

export class AuthTokenService extends RnAuthStorageService {
  setToken(token: string) {
    super.setToken(token);
  }
  removeToken() {
    super.removeToken();
  }
}
