import { BfPageStore } from '@tw/tw-runtime';
import { useEffect } from 'react';

export function usePageStoreLifecycle(page: BfPageStore<any>, params?: any) {
  useEffect(() => {
    page.onLoad(params);
    return () => {
      page.destroy();
    };
  }, [page, params]);
}
