import { TwPageProps } from './type';
import { FC, useMemo } from 'react';
import { SafeAreaView, StatusBar as StatusBarNative, View } from 'react-native';
import { styles } from '@/app/components/tw-page/styles';
// import { TwTheme } from '@tw/tw-runtime-rn';
import { StatusBar } from 'expo-status-bar';
import { TwTheme } from '@tw/tw-runtime-rn';
export const TwPage: FC<TwPageProps> = (props) => {
  const { style, translucentStatusBar, hideStatusBar, children, footer, statusBarProps } = props;

  const containerStyle = useMemo(() => {
    const paddingStyle = hideStatusBar
      ? undefined
      : {
          paddingTop: StatusBarNative.currentHeight
        };

    if (translucentStatusBar) {
      return [styles.container, paddingStyle, style];
    }
    return [styles.container, style];
  }, [hideStatusBar, style, translucentStatusBar]);
  return (
    <SafeAreaView style={containerStyle}>
      <StatusBar
        animated={true}
        translucent={translucentStatusBar}
        hidden={hideStatusBar}
        //@ts-ignorer
        backgroundColor={translucentStatusBar ? 'white' : TwTheme.values.primaryColors.base}
        {...statusBarProps}
      />
      <View style={styles.content}>{children}</View>
      <View style={styles.footer}>{footer}</View>
    </SafeAreaView>
  );
};
