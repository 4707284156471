import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import React, { useEffect, useMemo } from 'react';
import { SaleOrderList } from '@/sale/pages/sale-order-list/SaleorderList';
import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationRef } from '@tw/tw-runtime-rn';
import { Login } from '@/auth/pages/login/Login';
import { Splash } from '@/auth/pages/splash/Splash';
import { Verify } from '@/auth/pages/verify/Verify';
import { BfAppContext } from '@tw/tw-runtime';
import { SaleOrderInfo } from '@/sale/pages/order-info/SaleOrderInfo';
import { AuthSwitchAccount } from '@/sale/pages/account-switch/AuthSwitchAccount';
import { AuthLoginNew } from '@/sale/pages/login-new/AuthLoginNew';

const Stack = createStackNavigator();
const linking: LinkingOptions<any> = {
  prefixes: ['lock://', 'http://localhost']
};

const SCREEN_OPTIONS: StackNavigationOptions = {
  headerShown: false,
  cardStyle: {
    flex: 1
  }
};

export const AppRouter = () => {
  useEffect(() => {
    BfAppContext.getInstance().getRouteService().trigger('onReady');
  }, []);
  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <Stack.Navigator initialRouteName={ROUTE_NAMES.Splash} defaultScreenOptions={{}}>
        <Stack.Screen
          name={ROUTE_NAMES.Splash}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '初始化'
            }),
            []
          )}
          component={Splash}
        />
        <Stack.Screen
          name={ROUTE_NAMES.Login}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '登录'
            }),
            []
          )}
          component={Login}
        />
        <Stack.Screen
          name={ROUTE_NAMES.AuthLoginNew}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '登录'
            }),
            []
          )}
          component={AuthLoginNew}
        />
        <Stack.Screen
          name={ROUTE_NAMES.Verify}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '验证码'
            }),
            []
          )}
          component={Verify}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SaleOrderList}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '工单列表'
            }),
            []
          )}
          component={SaleOrderList}
        />
        <Stack.Screen
          name={ROUTE_NAMES.SaleOrderInfo}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '工单详情'
            }),
            []
          )}
          component={SaleOrderInfo}
        />
        <Stack.Screen
          name={ROUTE_NAMES.AuthSwitchAccount}
          options={useMemo(
            () => ({
              ...SCREEN_OPTIONS,
              title: '切换账号'
            }),
            []
          )}
          component={AuthSwitchAccount}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
