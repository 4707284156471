import { AbstractRnApiService } from '@tw/tw-runtime-rn';
import { Platform } from 'react-native';
console.log('AbstractRnApiService', AbstractRnApiService);

export class ApiService extends AbstractRnApiService {
  getBaseUrl(): string {
    return Platform.OS === 'web' ? '/api' : 'http://120.27.210.39:8900';
  }

  onNetworkError(error: any) {
    console.error(error);
    super.onNetworkError(error);
  }

  // protected handleResponseError(rawRes: AxiosResponse): any {
  //   console.log('请求报错', rawRes);
  //   this.logger.error('请求出错，返回数据' + JSON.stringify(rawRes.data));
  //   debugger
  //   return super.handleResponseError(rawRes);
  // }

  protected onTokenInvalidateError() {
    this.logger.error('Token无效，回到小程序页面');
    wx.miniProgram.reLaunch({
      url: `/pages/index/index?action=login`
    });
  }
}
