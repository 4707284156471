import { TwPinInputProps } from './type';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Dimensions, I18nManager, LayoutChangeEvent, Text, TextInput, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { styles } from '@/app/components/tw-pin-input/styles';
// import { useUnmount } from 'react-use';

export const TwPinInput: FC<TwPinInputProps> = (props) => {
  const {
    value = '',
    codeLength = 4,
    cellSize: cellSizeProp = 48,
    cellSpacing = 4,
    placeholder,
    password,
    mask = '*',
    autoCellSize = true,
    maskDelay = 200,
    autoFocus,
    style,
    cellStyle,
    cellStyleFocused,
    cellStyleFilled,
    textStyle,
    textStyleFocused,
    keyboardType = 'numeric',
    animationFocused = 'pulse',
    animated = true,
    editable = true,
    testID,
    inputProps,
    disableFullscreenUI,
    restrictToNumbers,
    onChangeText,
    onFulfill,
    onBackspace,
    onFocus,
    onBlur
  } = props;

  const ref = useRef(null);
  const maskTimeoutRef = useRef(0);
  const inputRef = useRef<TextInput>(null);
  const [enableMaskDelay, setEnableMaskDelay] = useState(false);
  const [focused, setFocused] = useState(false);

  const animate = useCallback(
    (animation = 'shake', duration = 650) => {
      if (!animated) {
        return new Promise((resolve, reject) => reject(new Error('Animations are disabled')));
      }
      return ref.current?.[animation](duration);
    },
    [animated]
  );

  // const shake = useCallback(() => animate({ animation: 'shake' }), [animate]);
  // const focus = useCallback(() => inputRef.current?.focus(), []);
  // const blur = useCallback(() => inputRef.current?.blur(), []);
  // const clear = useCallback(() => inputRef.current?.clear(), []);
  const onInputCode = useCallback(
    (code: string) => {
      if (restrictToNumbers) {
        code = (code.match(/[0-9]/g) || []).join('');
      }
      onChangeText?.(code);
      //
      if (code.length === codeLength) {
        onFulfill?.(code);
      }
      // 处理密码
      // only when input new char
      const enableMaskDelay = password && code.length > value.length;
      setEnableMaskDelay(enableMaskDelay);
      if (enableMaskDelay) {
        clearTimeout(maskTimeoutRef.current);
        maskTimeoutRef.current = setTimeout(() => {
          setEnableMaskDelay(false);
        }, maskDelay) as unknown as number;
      }
    },
    [codeLength, maskDelay, onChangeText, onFulfill, password, restrictToNumbers, value.length]
  );
  const onKeyPress = (e) => {
    if (e.nativeEvent.key === 'Backspace') {
      if (!value && onBackspace) {
        onBackspace();
      }
    }
  };
  const onFocused = (e) => {
    setFocused(true);
    onFocus?.(e);
  };

  const onBlurred = (e) => {
    setFocused(false);
    onBlur?.(e);
  };

  // useUnmount(() => {
  //   clearTimeout(maskTimeoutRef.current);
  // });
  useEffect(() => {
    return () => {
      clearTimeout(maskTimeoutRef.current);
    };
  }, []);
  const [cellSize, setCellSize] = useState<number>(cellSizeProp);

  const onContainerLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!autoCellSize) {
        return;
      }
      const width = e.nativeEvent.layout.width;
      const cellSize = width / codeLength - cellSpacing;
      setCellSize(cellSize);
    },
    [autoCellSize, cellSpacing, codeLength]
  );

  return (
    <Animatable.View
      ref={ref}
      style={[
        {
          alignItems: 'stretch',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'relative',
          // width: cellSize * codeLength + cellSpacing * (codeLength - 1),
          height: cellSize
        },
        style
      ]}
      onLayout={onContainerLayout}
    >
      <View
        style={{
          position: 'absolute',
          margin: 0,
          height: '100%',
          flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
          alignItems: 'center'
        }}
      >
        {/* eslint-disable-next-line prefer-spread */}
        {Array.apply(null, Array(codeLength)).map((_, idx) => {
          const cellFocused = focused && idx === value.length;
          const filled = idx < value.length;
          const last = idx === value.length - 1;
          const showMask = filled && password && (!enableMaskDelay || !last);
          const isPlaceholderText = typeof placeholder === 'string';
          const isMaskText = typeof mask === 'string';
          const pinCodeChar = value.charAt(idx);

          let cellText = null;
          if (filled || placeholder !== null) {
            if (showMask && isMaskText) {
              cellText = mask;
            } else if (!filled && isPlaceholderText) {
              cellText = placeholder;
            } else if (pinCodeChar) {
              cellText = pinCodeChar;
            }
          }

          const placeholderComponent = !isPlaceholderText ? placeholder : null;
          const maskComponent = showMask && !isMaskText ? mask : null;
          const isCellText = typeof cellText === 'string';

          return (
            <Animatable.View
              key={idx}
              style={[
                {
                  width: cellSize,
                  height: cellSize,
                  marginLeft: cellSpacing / 2,
                  marginRight: cellSpacing / 2,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                },
                cellStyle || styles.cellUnderlineStyle,
                cellFocused ? cellStyleFocused || styles.cellUnderlineStyle__focused : {},
                filled ? cellStyleFilled : {}
              ]}
              animation={idx === value.length && focused && animated ? animationFocused : null}
              iterationCount="infinite"
              duration={500}
            >
              {isCellText && !maskComponent && (
                <Text style={[styles.pinTextStyle, textStyle, cellFocused ? textStyleFocused : {}]}>{cellText}</Text>
              )}

              {!isCellText && !maskComponent && placeholderComponent}
              {isCellText && maskComponent}
            </Animatable.View>
          );
        })}
      </View>
      <TextInput
        disableFullscreenUI={disableFullscreenUI}
        value={value}
        ref={inputRef}
        onChangeText={onInputCode}
        onKeyPress={onKeyPress}
        onFocus={onFocused}
        onBlur={onBlurred}
        spellCheck={false}
        autoFocus={autoFocus}
        keyboardType={keyboardType}
        numberOfLines={1}
        caretHidden
        maxLength={codeLength}
        selection={{
          start: value.length,
          end: value.length
        }}
        style={{
          flex: 1,
          backgroundColor: 'red',
          opacity: 0,
          textAlign: 'center'
        }}
        testID={testID || undefined}
        editable={editable}
        {...inputProps}
      />
    </Animatable.View>
  );
};
