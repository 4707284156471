import { BfAppContext, BfPageStore, CountDownTimer, newEnumValue, OnTimerFinish, OnTimerUpdate } from '@tw/tw-runtime';
import { LoadParams, VerifyCodeState } from '@/auth/stores/verify-code/types';
import { VERIFY_CODE_CONFIG } from '@/auth/stores/verify-code/config';
import { ResLoginRequestModel } from '@tw/lock-api-gen/dist/models';
import {
  CaptchaUsageEnumValue,
  LoginDeviceTypeEnumValue,
  LoginMethodEnumValue,
  ResAuthorizationService,
  UserTypeEnumValue
} from '@tw/lock-api-gen';
import { AuthConstants } from '@/auth/config/auth-constants';

/**
 * 验证码页面逻辑
 */
export class VerifyCodeStore extends BfPageStore<VerifyCodeState> {
  private authService: ResAuthorizationService;
  private countDownTimer: CountDownTimer;
  constructor() {
    super({});
    const api = BfAppContext.getInstance().getApiService();
    this.authService = new ResAuthorizationService(api);
    this.countDownTimer = new CountDownTimer({
      onFinish: this.onCountDownFinish,
      onTimer: this.onCountDownTimer
    });
  }

  /**
   * 倒计时结束
   */
  private onCountDownFinish: OnTimerFinish = () => {
    //
    this.setState({
      isCodeSendCounting: false,
      codeSendTimeRemind: 0
    });
  };

  private onCountDownTimer: OnTimerUpdate = (time) => {
    //
    this.setState({
      isCodeSendCounting: true,
      codeSendTimeRemind: time
    });
  };

  destroy() {
    this.countDownTimer.stop();
    super.destroy();
  }

  /**
   * 页面加载
   * @param param
   */
  onLoad(param?: LoadParams): Promise<void> {
    this.setPhone(param.phone);
    if (param.phone) {
      this.sendCode();
    }
    return super.onLoad(param);
  }

  /**
   * 设置手机号
   * @param phone
   */
  setPhone(phone: string) {
    this.setState({
      phone: phone
    });
  }

  /**
   * 设置验证码
   * @param code
   */
  setCode(code: string) {
    this.setState({
      code: code
    });
  }

  /**
   * 发送验证码
   */
  async sendCode() {
    this.countDownTimer.start(AuthConstants.captchaSendTimeMs, AuthConstants.captchaSendUpdatePeriodMs);
    const { phone } = this.getState();
    this.logger.debug('发送验证码：' + phone);
    await this.authService.sendCaptcha({
      userType: newEnumValue(UserTypeEnumValue.person),
      identifier: phone,
      usage: newEnumValue(CaptchaUsageEnumValue.login)
    });
    this.messageService.showSuccess('验证码发送成功');
  }

  /**
   *  用户录入验证码
   */
  onCodeFulfill(code: string) {
    //
    this.setCode(code);
    this.verifyCodeAndLogin().then();
  }

  /**
   * 请求登录
   */
  async verifyCodeAndLogin() {
    const { code, phone } = this.getState();
    //
    if (!code || code.length !== VERIFY_CODE_CONFIG.codeLength) {
      //
      this.messageService.showError('请输入有效的验证码');
      return;
    }
    this.logger.debug(`请求登录 ${code} ${phone}`);
    await BfAppContext.getInstance()
      .getAppStore()
      .login(<ResLoginRequestModel>{
        userType: newEnumValue(UserTypeEnumValue.company),
        loginMethod: newEnumValue(LoginMethodEnumValue.captcha),
        userName: phone,
        captcha: code,
        deviceType: newEnumValue(LoginDeviceTypeEnumValue.android)
      });
  }
}
