import { BfAppContext } from '@tw/tw-runtime';
import { debounce } from 'lodash-es';

export class BfAppErrorAlert {
  static ROUTE_NAME = '_ERROR_ALERT';

  private errors: BfAppError[] = [];

  private static instance: BfAppErrorAlert = new BfAppErrorAlert();

  public static getInstance() {
    return this.instance;
  }

  clearAll() {
    this.errors = [];
  }

  getErrors() {
    return this.errors;
  }

  /**
   * 显示错误
   * @param error
   */
  showError(error: BfAppError) {
    this.errors.push(error);
    this.showErrorPage();
  }

  private showErrorPage = debounce(() => {
    const routeService = BfAppContext.getInstance().getRouteService();
    routeService.push({
      path: BfAppErrorAlert.ROUTE_NAME
    });
  }, 500);
}

export interface BfAppError {
  /**
   * 本地异常
   */
  isNative?: boolean;
  /**
   * 异常信息
   */
  message?: string;
  /**
   * 错误
   */
  error: Error;
}
