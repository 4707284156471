import { FC } from 'react';
import { ProductProductProps } from '@/sale/components/product-product/type';
import { View, Text } from 'react-native';
import { styles } from './styles';

export const ProductProduct: FC<ProductProductProps> = (props) => {
  const { value } = props;
  return (
    <View style={styles.info}>
      <Text style={styles.infoTitle}>{value?.name}</Text>
      <Text style={styles.infoSize}>{value?.specification ?? '--'}</Text>
    </View>
  );
};
