import { View, ViewProps, Image, Text } from 'react-native';
import { styles } from '@/auth/pages/login/viewparts/login-header/styles';
import { FC } from 'react';
import LoginLogo from '../../assets/shape.png';

export const LoginHeader: FC<ViewProps> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <Image source={LoginLogo} style={styles.logo} resizeMode={'contain'}></Image>
      <Text style={styles.slogan}>欢迎语</Text>
    </View>
  );
};
