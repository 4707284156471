import { ScreenProp } from '@/app/navigation/types';
import React, { useState } from 'react';
import { styles } from '@/sale/pages/login-new/styles';
import { LoginHeader } from '@/sale/pages/login-new/login-header/LoginHeader';
import { KeyboardAvoidingView, View, Text } from 'react-native';
import { TwPage, TwPageHeader, TwRow } from '@tw/tw-components-rn';
import { LoginFormVerification } from '@/sale/pages/login-new/login-form-verification/LoginForm';
import { BfAppContext } from '@tw/tw-runtime';

export const AuthLoginNew = ({ navigation, route }: ScreenProp) => {
  const onBack = () => {
    BfAppContext.getInstance().getRouteService().back();
  };

  return (
    <TwPage translucentStatusBar={true} style={styles.container}>
      <TwPageHeader showBack={true} onBack={onBack} />
      <LoginHeader style={styles.header} />
      <KeyboardAvoidingView>
        <LoginFormVerification style={styles.loginFrom} />
      </KeyboardAvoidingView>
    </TwPage>
  );
};
