import { ScreenProp } from '@/app/navigation/types';
import React, { useEffect, useState } from 'react';
import { LoginForm } from '@/auth/pages/login/viewparts/login-form/LoginForm';
import { styles } from '@/auth/pages/login/styles';
import { OtherLogin } from '@/auth/pages/login/viewparts/other-login/OtherLogin';
import { LoginHeader } from '@/auth/pages/login/viewparts/login-header/LoginHeader';
import { KeyboardAvoidingView } from 'react-native';
import { TwPage } from '@/app/components/tw-page';
import { LoginStore } from '@/auth/stores/login/login-store';
import { usePageStoreLifecycle } from '@/app/hooks/use-page-store-lifecycle';
import { BfPageStoreProvider } from '@tw/tw-runtime-react';

export const Login = ({ navigation, route }: ScreenProp) => {
  const [store] = useState(() => new LoginStore());

  usePageStoreLifecycle(store, route.params);
  return (
    <BfPageStoreProvider store={store}>
      <TwPage translucentStatusBar={true} style={styles.container}>
        <LoginHeader style={styles.header} />
        <KeyboardAvoidingView>
          <LoginForm style={styles.loginFrom} />
        </KeyboardAvoidingView>
        <OtherLogin style={styles.other} />
      </TwPage>
    </BfPageStoreProvider>
  );
};
