import { Divider, HStack, Image, Link, Text } from 'native-base';
import WeixinImg from '@/auth/pages/login/weixin.png';
import React, { FC } from 'react';
import { View, ViewProps } from 'react-native';

export const OtherLogin: FC<ViewProps> = (props) => {
  return (
    <View style={props.style}>
      <HStack my={4} space="2" alignItems="center" justifyContent="center">
        <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
        <Text
          fontWeight="medium"
          _light={{
            color: 'coolGray.400'
          }}
          _dark={{
            color: 'coolGray.300'
          }}
        >
          更多登录方式
        </Text>
        <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
      </HStack>
      <HStack mt={{ base: 6, md: 4 }} justifyContent="center" alignItems={'center'} space="4">
        <Link>
          <Image width="6" height="6" source={WeixinImg} alt="Alternate Text" />
        </Link>
      </HStack>
    </View>
  );
};
